@import 'assets/css/variables';
@import 'assets//css/mixins';

.page-container {
  position: relative;
  padding: 0;
  overflow-x: visible;
  min-height: 100vh;
  background-color: $white;
  &__content {
    padding: 0 10rem !important;
    margin-bottom: 10rem;
    @include respond(tab-land) {
      padding: 0 2rem !important;
      margin-bottom: 5rem;
    }
  }
}

.navbar {
  min-height: 8rem;
  padding: 0 10rem !important;
  @include respond(tab-land) {
    padding: 0 2rem !important;
  }
  &__title {
    width: 18rem;
    height: 3.8rem;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__items {
    display: flex;
    align-items: center;
    a {
      color: $lightBlack;
      text-decoration: none;
      font-family: $font-secondary-regular;
      line-height: 3.2rem;
      font-size: 2rem;
      &:last-child {
        margin-left: 3.2rem;
      }
    }
  }
  .back-button-container {
    min-width: 10rem;
    @include respond(phone) {
      min-width: 4rem;
    }
    a {
      color: $lightBlack;
      text-decoration: none;
      font-family: $font-secondary-regular;
      line-height: 3.2rem;
      font-size: 2rem;
      gap: 5px;
      cursor: pointer;
    }
  }
}

.nav-item {
  width: 100%;
  border-bottom: 1px rgb(124, 124, 124, 0.2) solid;
  height: 40px;
  display: flex;
  align-items: center;
  a {
    color: $lightBlack;
    text-decoration: none;
    font-family: $font-secondary-regular;
    line-height: 3.2rem;
    font-size: 2rem;
    gap: 5px;
    margin-left: 20px;
  }
}

.navbar-toggler {
  border: none;
  outline: none;
}

.navbar-toggler {
  border: none;
  text-decoration: none;
}

.footer {
  background-color: $darkBlue10;
  min-height: 13rem;
  padding: 5.6rem 10rem !important;
  bottom: 0;
  width: 100%;
  z-index: 2;
  @include respond(phone) {
    padding: 4rem 8rem !important;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include respond(tab-land) {
      flex-direction: column;
    }
    .social-links {
      a {
        img {
          width: 32px;
          height: 32px;
        }
        @include respond(phone) {
          img {
            width: 24px;
            height: 24px;
          }
        }
        &:hover {
          opacity: 0.7;
          transition: all ease 0.3s;
        }
        &:not(:first-child) {
          margin-left: 4rem;
          @include respond(phone) {
            margin-left: 1rem;
          }
        }
      }
    }
  }
  &__column {
    @include respond(tab-land) {
      margin-bottom: 4rem;
    }
    &__paragraph {
      color: $white;
      margin-bottom: 0;
      text-align: center;
      font-size: 1.2rem;
    }
  }
}

.headline-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  @include respond(tab-land) {
    margin-top: 3rem;
  }
  h1 {
    font-size: 6.4rem;
    font-family: $font-bold;
    line-height: 5rem;
    margin-bottom: 3.2rem;
    @include respond(tab-land) {
      font-size: 3.5rem;
      line-height: 2rem;
    }
  }
  h2 {
    font-size: 2.4rem;
    text-align: center;
    margin-bottom: 2rem;
    max-width: 560px;
    @include respond(tab-land) {
      font-size: 2rem;
    }
  }
}

.image-upload {
  display: flex;
  justify-content: center;
  &__wrapper {
    background-color: $white;
    padding: 1.2rem;
    max-width: 46rem;
    // height: 51.5rem;
    border-radius: 2.4rem;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
    &--rotate {
      transform: rotate(-5deg);
      width: 40rem;
      @include respond(phone) {
        width: 35rem;
      }
    }
  }
  &__empty-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 51.5rem;
    @include respond(phone) {
      height: 32rem;
    }
  }
  &__content {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23919BB4FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 12px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #e9ebf0;
    color: $darkBlue10;
    .image-upload__preview {
      width: 100%;
      height: 100%;
      position: relative;
      img {
        width: 100%;
        // height: 100%;
        object-fit: contain;
        border-radius: 12px;
      }
    }
    .upload-title {
      font-family: $font-bold;
      font-size: 3.2rem;
      line-height: 4rem;
      margin-bottom: 2rem;
    }
    .description {
      font-family: $font-light;
      font-size: 1.6rem;
      line-height: 2.4rem;
      text-align: center;
      width: 60%;
      span {
        font-family: $font-bold;
      }
    }
    .divider {
      position: relative;
      width: 60%;
      margin: 4rem 0;
      p {
        font-size: 1.6rem;
        color: $darkBlue10;
        line-height: 3rem;
        position: absolute;
        bottom: -2.4rem;
        left: 41%;
        background-color: #e9ebf0;
        padding: 0 1rem;
        z-index: 1;
      }
      span {
        height: 0.1rem;
        width: 100%;
        background-color: $darkBlue10;
        position: absolute;
      }
    }
  }
}

.general-button {
  padding: 0 2rem;
  height: 5.2rem;
  border-radius: 1rem;
  color: $white;
  span {
    font-size: 2rem;
    font-family: $font-medium;
    @include respond(phone) {
      font-size: 16px;
    }
  }
  &--green {
    background-color: $green10;
    border: 0;
    &:hover {
      background-color: #49c28b;
      transition: all ease 0.3s;
    }
  }
  &--blue {
    background-color: $darkBlue10;
    border: 0;
    &:hover {
      background-color: #394c78;
      transition: all ease 0.3s;
    }
  }
  &--bordered {
    background-color: $white;
    border: 0.2rem solid $green10;
    color: $green10;
    &:hover {
      background-color: #2cb36e;
      color: $white;
      transition: all ease 0.3s;
    }
  }
}

.multiple-rows-carousel {
  display: flex;
  flex-wrap: wrap;
  gap: 1.4rem;
  position: relative;
  margin-bottom: 2rem;
  @include respond(phone) {
    justify-content: center;
  }

  .arrow {
    background-color: $green;
    border-radius: 50%;
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    cursor: pointer;
    &--prev {
      transform: rotateY(180deg);
      top: 50%;
      left: -4rem;
      @include respond(tab-port) {
        top: 103%;
        left: 45%;
      }
    }
    &--next {
      top: 50%;
      right: 3rem;
      @include respond(tab-port) {
        top: 103%;
        left: 50%;
      }
      &-prev {
        @include respond(tab-port) {
          left: 55%;
        }
      }
    }
  }
  &__item {
    width: 11rem;
    height: 14.7rem;
    cursor: pointer;
    @include respond(phone) {
      width: 80px;
      height: 132px;
    }
    @media (max-width: 350px) {
      width: 80px;
      height: 132px;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      object-fit: cover;
    }
  }
}

.custom-carousel {
  .slick-prev {
    display: none !important;
  }
  .slick-next {
    background-color: $green;
    border-radius: 50%;
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    // right: 1rem !important;
    &:before {
      content: url('../assets/icons/right-arrow.svg');
    }
  }
}

.templates-carousel {
  height: 13rem;
  display: flex;
  // .slick-slide {
  //   div {
  //     &:first-child {
  //       margin-right: 1rem;
  //     }
  //   }
  // }
  &__item {
    position: relative;
    height: 13rem;
    width: 11rem;
    margin-bottom: 1.6rem;
    margin-right: 1.2rem;
    border-radius: 0.8rem;
    cursor: pointer;
    outline: none !important;
    border: 0.6rem solid $gray2;
    &--active {
      border: 0.4rem solid $green10;
      img {
        border: 0.2rem solid $gray2;
      }
    }
    .top-line,
    .bottom-line {
      position: absolute;
      width: 75%;
      height: 1rem;
      border-radius: 0.4rem;
      background-color: $green10;
    }
    .top-line {
      top: 0.5rem;
      left: 50%;
      transform: translate(-50%, 0);
    }
    .bottom-line {
      bottom: 0.5rem;
      left: 50%;
      transform: translate(-50%, 0);
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 3px;
    }
  }
}

.general-input {
  label {
    display: block;
    font-size: 2rem;
    line-height: 3rem;
    font-family: $font-medium;
  }
  input {
    font-size: 2rem;
    width: 100%;
    border-radius: 1rem;
    border: 0.1rem solid $gray;
    color: $gray10;
    padding: 1rem 2rem;
    margin-top: 1rem;
    font-family: $font-light;
    &:focus {
      outline: none;
    }
  }
}

.download-and-share {
  .options-group {
    margin-bottom: 4rem;
    p {
      font-size: 2rem;
      line-height: 3rem;
      font-family: $font-medium;
    }
  }
  .social-media-icons {
    @include respond(phone) {
      display: flex;
      justify-content: space-between;
    }
  }
  .copy-input {
    border: 0.1rem solid $gray2;
    padding: 0.3rem 0.3rem 0.3rem 1.2rem;
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
    span {
      font-family: $font-light;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 10px;
    }
    button {
      background-color: $darkBlue10;
      color: $white;
      font-family: $font-medium;
      font-size: 2rem;
      outline: none;
      border: 0;
      padding: 0.5rem 2rem;
      border-radius: 0.7rem;
      &:hover {
        background-color: #394c78;
        transition: all ease 0.3s;
      }
    }
  }
}

.modal-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 40rem;
  @include respond(phone) {
    min-width: 35rem;
  }
  .image-preview {
    background-color: $white;
    padding: 1.2rem;
    width: 30.5rem;
    // height: 40rem;
    border-radius: 1.2rem;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
    transform: rotate(-5deg);
    margin: 4rem 0;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 1.2rem;
    }
  }
  .close-btn {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #b8b8b8;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.top-text,
.bottom-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: $white;
  // word-break: break-all;
  width: 100%;
  padding: 0 3rem;
  text-align: center;
  text-transform: uppercase;
  font-family: $font-tertiary;
  -webkit-text-stroke: 1px black;
  -webkit-text-fill-color: white;
}
.top-text {
  top: 2rem;
}
.bottom-text {
  bottom: 2rem;
}

.carousel-arrow {
  background-color: $green;
  border-radius: 50%;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  &--prev {
    transform: rotateY(180deg);
    top: 45%;
    left: 0;
    z-index: 10 !important;
    @include respond(phone) {
      left: 1rem;
    }
    @include respond(phone-m) {
      left: -1rem;
    }
  }
  &--next {
    top: 45%;
    right: 0;
    @include respond(phone) {
      right: 1rem;
    }
    @include respond(phone-m) {
      right: -1rem;
    }
  }
  &--loading {
    background-color: #b8b8b8;
  }
}

.carousel-item {
  display: flex !important;
  justify-content: center;
  // width: 184px !important;
  img {
    width: 184px !important;
    height: 245px;
    object-fit: cover;
    border-radius: 10px;
    background-color: hsl(200, 20%, 95%);

    @include respond(tab-port) {
      width: 140px !important;
      height: 160px;
      border-radius: 10px;
    }
    @include respond(phone) {
      width: 88px !important;
      height: 132px;
      border-radius: 10px;
    }
  }
}

.slick-track {
  margin-left: 0px !important;
  transform: translate3d(0px, 0px, 0px);
}

.social-media-icons {
  display: flex;
  align-items: center;
}

.x-icon-container {
  background-color: black;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  .x-icon {
    width: 20px;
    height: 20px;
  }
}
